.content-image {
    width: 100%;
    height: 100%;
    position: relative;
}

.button-close-image {
    right: 10px;
    position: absolute;
    top: 10px;
}

.circle-user {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: rgb(134, 134, 134);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 22px;
}